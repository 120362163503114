
.map {
    height: 100vh;
}
.innerFrameKontakt {
    border: 1px solid #FF6D00;
    color: #FFF;
    padding: 10px;
    height: 250px;
    width: 100%;
    pointer-events: none;
}
.estebanFont {
    font-family: 'Esteban';
}
.clickerfont {
    font-family: 'Clicker';
}
.smallText {
    font-size: 16px;
}