@font-face {
    font-family: 'Esteban';
    src: local('Esteban'),
    url('./font-family/Esteban/Esteban-Regular.ttf') format('truetype')
}
@font-face {
    font-family: 'Clicker';
    src: local('Clicker'),
    url('./font-family/Clicker_Script/ClickerScript-Regular.ttf') format('truetype')
}

.textWhite{
    font-family: 'Esteban';
    color: #FFF;
}

body {
    background-color: #F8F9FA;
    max-height: 100vh;
    margin: 0px;
}

/*Hero page*/
.heroPage {
    height: fit-content;
    overflow: hidden;
}
.videoBkg {
    width: 100%;
    z-index: -5;
    object-fit: cover;
}
.textCont {
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    color: #F8F9FA;
    filter: brightness(100%);
}
.txtWelcome {
    font-family: 'Esteban';
    font-size: 5vw;
    margin: 0;
    font-weight: 200;
    opacity: 0;
    animation-name: opacityWelcome;
    animation-duration: 5s;
    animation-fill-mode: forwards;
}
@keyframes opacityWelcome {
    100% {
        opacity: 1;
    }
}

.txtName {
    font-family: 'Clicker';
    font-size: 15vw;
    margin: 0;
    font-weight: 100;
    color: #FF6D00;
}
.txtInfo {
    font-family: 'Esteban';
    margin: 0;
    font-size: 3vw;
}
.telefonNr {
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (min-width: 1600px) {
    .txtWelcome {
       font-size: 80px;
    }
    .txtName {
        font-size: 100px;
    }
    .txtInfo {
        font-size: 18px;
    }
}

/* About us section*/
.titleParagraph {
    font-family: 'Esteban';
    font-size: 70px;
}
.subtitle {
    font-family: 'Clicker';
    font-size: 24px;
}
.paragraphIcon {
    width: 30px;
    height: 30px;
}
.paragraph {
    font-family: 'Esteban';
    font-family: 16px;
}
.teamImg {
    width: 350px;
    height: 350px;
    object-fit: cover;
    border: 10px solid rgb(63, 63, 63);
}
.puzzleCont {
    width: 350px;
    height: 350px;
    background-image: url('./images/home/presentationPlate.jpeg');
    background-position: center;
    background-size: cover;
}
.whiteSquare {
    height: 50%;
    width: 50%;
    background-color: #F8F9FA;
}
.puzzlePiece {
    background-image: url('./images/home/presentationPlate.jpeg');
    background-position: 20% 10%;
    background-size: 300%;
    width: 95%;
    height: 95%;
}

/*Banner*/
.bannerRow {
    background-image: url('./images/home/banner1.jpeg');
    background-position: center;
    background-size: cover;
    height: 500px;
}
.glassMotto {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    height: 100px;
    line-height: 100px;
    flex: 1;
    color: #F8F9FA;
    font-family: 'Clicker';
    font-size: 150%;
}
